import React from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import FloatingButton from './FloatingButton';
import { FaRupeeSign } from "react-icons/fa";



const Home = () => {
    const counters = [
        { icon: 'flaticon-calendar', value: 120, label: 'Finished Event' },
        { icon: 'flaticon-heart-beat', value: 120, label: 'Finished Event' },
        { icon: 'flaticon-in-love', value: 120, label: 'Finished Event' },
        { icon: 'flaticon-hug', value: 120, label: 'Finished Event' },
    ];

    return (
        <div>
            {/*-------------------------- home slider--------------------- */}
            <div className="slider_area">
                <div className="single_slider  d-flex align-items-center slider_bg_1 overlay2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="slider_text ">
                                    <span>Get Started Today.</span>
                                    <h3> Alfred solomon Heart Foundation</h3>

                                    <Link to="/donate" className="boxed-btn3">Donate now
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container box_1170">
                <div className="section-top-border">
                    <h3 className="mb-30" style={{ textAlign: 'center' }}>OUR WORKS</h3>
                    <div className="row">
                        <div className="col-md-3 col-lg-4">
                            <img src="assets/img/home1.jpg" alt="" className="img-fluid" style={{ height: '300px', width: '500px' }} />
                        </div>
                        <div className="col-md-9 col-lg-8  mt-sm-20">
                            <p>The Alfred Solomon Heart Foundation is an endeavour on the part of the Solomon family to achieve the ideals set forth by Alfred Solomon to help the poor and to utilize the skills he had facilitated his son to acquire to reach out to the needy who require treatment for heart disease. An endowment fund has been set up in his memory at Loyola College, Chennai to award to toppers in the department of Mathematics.<br></br>
                            Dr Solomon subsequently joined the Department of Cardiothoracic and Vascular Surgery, Apollo Hospital, Chennai under Dr. M.R.Girinath as Senior Registrar and was soon promoted to the position of Junior Consultant. This is one of the premier centers in the country performing 1500-2000 open-heart procedures annually.</p>
                        </div>
                    </div>
                </div>

                <div className="counter_area">
                    <div className="container">
                        <div className="counter_bg overlay">
                            <div className="row">
                                {counters.map((counter, index) => (
                                    <div className="col-lg-3 col-md-6" key={index}>
                                        <div className="single_counter d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <i className={counter.icon}></i>
                                            </div>
                                            <div className="events">
                                                <h3 className="counter">
                                                    <CountUp start={0} end={counter.value} duration={3.5} />
                                                </h3>
                                                <p>{counter.label}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                {/* ---------------------Services-------------------------------- */}
                <div className="service-heading white-heading mt-5 mb-5">
                            <span>What We Do</span> Our Services
                <section className="our-works mt-3 mb-3">
                    
                    {/* <div className="container">
                        <div className="service-heading white-heading">
                            <span>What We Do</span> Our Services
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="service-block">
                                    <div className="inner-box">
                                        <div className="image">
                                            <Link to="#"><img src="assets/img/service-1.jpg" alt="" /></Link>
                                            <div className="read-more"><Link to="/contact">Contact us</Link></div>
                                        </div>
                                        <div className="lower-content">
                                            <div className="text">The Alfred Solomon Heart Foundation is a non-profit Charitable trust set up in memory of the late Mr Alfred Solomon, ex-Chief General Manager, State Bank of India, Andhra Pradesh and former Chairman,Catholic Syrian Bank.</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="service-block">
                                    <div className="inner-box">
                                        <div className="image">
                                            <Link to="#"><img src="assets/img/service-2.jpg" alt="" /></Link>
                                            <div className="read-more"><Link to="/contact">Contact us </Link></div>
                                        </div>
                                        <div className="lower-content">
                                            <div className="text">To partially or wholly subsidize cardiac surgery/ other Cardiac procedures for children of parents from the lower socio-economic strata To purchase valves for patients with rheumatic .</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="service-block">
                                    <div className="inner-box">
                                        <div className="image">
                                            <Link to="#"><img src="assets/img/service-3.jpg" alt="" /></Link>
                                            <div className="read-more"><Link to="/contact">Contact us </Link></div>
                                        </div>
                                        <div className="lower-content">
                                            <div className="text">The trust is run entirely on voluntary donations which are 50% exempt from income tax under section 80G of the income tax act. The trust is entirely dependent on the generosity of people.</div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-sm-12">
                                <Link to="/donate" className="btn">Make a Donate  <FaRupeeSign style={{fontSize:'13px',paddingTop:'2px'}} /></Link>
                            </div>
                        </div>
                    </div> */}
                    <article class="article-card">
                        <img
                            class="card__background"
                            src="assets/img/service-1.jpg"
                            alt="service img"
                            width="1920"
                            height="2193"
                        />
                        <div class="card__content | flow">
                            <div class="card__content--container | flow">
                                <h2 class="card__title" style={{ color: '#ffff' }}>What we do</h2>
                                <p class="cards__description">
                                The Alfred Solomon Heart Foundation is a non-profit Charitable trust set up in memory of the late Mr Alfred Solomon, ex-Chief General Manager, State Bank of India, Andhra Pradesh and former Chairman,Catholic Syrian Bank.
                                </p>
                            </div>
                        </div>
                    </article>
                    <article class="article-card">
                        <img
                            class="card__background"
                            src="assets/img/service-2.jpg"
                            
                            alt="service img"
                            width="1920"
                            height="2193"
                        />
                        <div class="card__content | flow">
                            <div class="card__content--container | flow">
                                <h2 class="card__title" style={{ color: '#ffff' }}>What we do</h2>
                                <p class="cards__description">
                                To partially or wholly subsidize cardiac surgery/ other Cardiac procedures for children of parents from the lower socio-economic strata To purchase valves for patients with rheumatic 
                                </p>
                            </div>
                        </div>
                    </article>
                    <article class="article-card">
                        <img
                            class="card__background"
                            src="assets/img/service-3.jpg"
                            
                            alt="service img"
                            width="1920"
                            height="2193"
                        />
                        <div class="card__content | flow">
                            <div class="card__content--container | flow">
                                <h2 class="card__title" style={{ color: '#ffff' }}>What we do</h2>
                                <p class="cards__description">
                                The trust is run entirely on voluntary donations which are 50% exempt from income tax under section 80G of the income tax act. The trust is entirely dependent on the generosity of people.
                                </p>
                            </div>
                        </div>
                    </article>

                </section>
                </div>


            </div>
            <FloatingButton />
        </div>
    )
}

export default Home