import React from 'react'
import { Link } from 'react-router-dom';
import FloatingButton from './FloatingButton';


const News = () => {

    
  return (
    <div>

         {/* ----------------------------breadcrumbs------------------------ */}
         <div className="bradcam_area breadcam_bg overlay d-flex align-items-center justify-content-center">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="bradcam_text text-center">
                                <h3>News</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

<div className="news__area section_padding">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-6">
                    <div className="section_title text-center mb-5">
                        <h3><span>News & Updates</span></h3>
                    </div>
                </div>
            </div>
            <div className="container">
                <h3> Message From Chairman:</h3>
                <p>The pediatric cardiac program at apollo childrens hospital, chennai has successfully reached out to over 6000 poor children with heart disease and rectified them either by open heart surgery or intervention.</p><br></br>
                <p>While the Alfred Solomon Heart Foundation has sponsored some children, the majority were done under various government schemes and with huge support from the Apollo hospitals management and the save a childs heart initiative (SACHI) trust run by Apollo childrens hospitals, chennai.</p><br></br>
                <p>These poor children are offered world-class high end procedures like arterial switch operation, TAPVC repair, valve repairs, Tetralogy Of Fallot repairs, ventricular septal defect closures in babies as small as 2.5-3 Kgs and ductal stenting.</p><br></br>
                <p>These poor children are offered world-class high end procedures like arterial switch operation, TAPVC repair, valve repairs, Tetralogy Of Fallot repairs, ventricular septal defect closures in babies as small as 2.5-3 Kgs and ductal stenting.</p><br></br>
            </div>

<div class="news-center mt-4">
  <div class="news-article-card news-card-1">
    <img src="assets/img/news/News1.jpeg" alt="article-cover" />
  </div>
  <div class="news-article-card news-card-2">
    <img src="assets/img/news/News2.jpeg" alt="article-cover" />
  </div>
  <div class="news-article-card news-card-3">
    <img src="assets/img/news/News3.jpeg" alt="article-cover" />
  </div>
</div>

        </div>
    </div>
    <FloatingButton/>
    </div>
  )
}

export default News