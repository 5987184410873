import React,{useState} from 'react'
import { Link} from 'react-router-dom';
import { submitQueryForm } from '../../pages/ApiService';


const Footer = () => {
    const currentYear = new Date().getFullYear();

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = {
            email: email,
            message: message,
        };

        try {
            const response = await submitQueryForm(formData); // Call the submitQueryForm function
            setSuccessMessage('Form submitted successfully!');
            // Optionally, you can add more logic here to handle the response.
        } catch (error) {
            setError('Error submitting form: ' + error.message);
            // Optionally, you can add more logic here to handle the error.
        }
    };
    return (
        <div>
            <footer className="footer">
                <div className="footer_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-md-6 col-lg-4 ">
                                <div className="footer_widget">
                                    <div className="footer_logo">
                                        <Link to="/">
                                            <img src="assets/img/logo-2.png" alt="" />
                                        </Link>
                                    </div>

                                    <p className="address_text">The Alfred Solomon Heart Foundation is a non-profit Charitable trust set up in memory of the late Mr Alfred Solomon, ex-Chief General Manager,State Bank of India, Andhra Pradesh and former Chairman, Catholic Syrian Bank.
                                    </p>
                                    {/* <div className="socail_links">
                                        <ul>
                                            <li>
                                                <Link to="#">
                                                    <i className="ti-facebook"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    <i className="ti-twitter-alt"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    <i className="fa fa-dribbble"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    <i className="fa fa-instagram"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div> */}

                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 col-lg-2 ">
                                <div className="footer_widget footer-links">
                                    <h3 className="footer_title">
                                        Contact us
                                    </h3>
                                   <p><span className="contact-info__icon"><i className="ti-tablet"></i></span> <Link to="tel:+919655027828">+91 9655027828</Link><br>
                                   </br>
                                   <span className="contact-info__icon"><i className="ti-email"></i></span>
                                   <Link to="mailto:nevillesolomon@gmail.com">nevillesolomon@gmail.com</Link>
                                   </p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 col-lg-3">
                                <div className="footer_widget footer-links">
                                    <h3 className="footer_title">
                                        Address
                                    </h3>
                                    <div className="contacts">
                                        <p>ALFRED HEART FOUNDATION:<br></br>
                                            No 15, Apollo children’s Hospital,<br></br>
                                            iIP- Block, 4th floor,

                                            <br></br>
                                            Safee Mohammed Road,


                                            <br></br>Chennai – 600006.




                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 col-lg-3 footer-links">
                                {/* <div className="footer_widget">
                                    <h3 className="footer_title">
                                        Top News
                                    </h3>
                                    <ul className="news_links">
                                        <li>
                                            <div className="thumb">
                                                <Link to="#">
                                                    <img src="img/news/news_1.png" alt="" />
                                                </Link>
                                            </div>
                                            <div className="info">
                                                <Link to="#">
                                                    <h4>School for African
                                                        Childrens</h4>
                                                </Link>
                                                <span>Jun 12, 2019</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="thumb">
                                                <Link to="#">
                                                    <img src="img/news/news_2.png" alt="" />
                                                </Link>
                                            </div>
                                            <div className="info">
                                                <Link to="#">
                                                    <h4>School for African
                                                        Childrens</h4>
                                                </Link>
                                                <span>Jun 12, 2019</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div> */}
                                <aside className="single_sidebar_widget newsletter_widget ">
                                    <h4 className="widget_title">Ask Query</h4>

                                    <form onSubmit={handleSubmit}>
            <div className="form-group footer-links-mail">
                <input
                    type="email"
                    className="form-control"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Enter query"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                />
            </div>
            <button
                className="button rounded-0 primary-bg text-white w-100 btn_1 boxed-btn"
                type="submit"
            >
                Send
            </button>
            {error && <p className="text-danger">{error}</p>}
            {successMessage && <p className="text-success">{successMessage}</p>}
        </form>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copy-right_text">
                    <div className="container">
                        <div className="row">
                            <div className="bordered_1px "></div>
                            <div className="col-xl-12">
                                <p className="copy_right text-center">
                                <p>
      Copyright &copy; {currentYear} <Link to="" target="_blank" rel="noopener noreferrer"> Alfred Heart Foundation</Link> All rights reserved | Designed by  
      <Link to="https://brandingworld.net/" target="_blank" rel="noopener noreferrer"> Branding World .</Link>
    </p>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer