import logo from './logo.svg';
import './App.css';
import { BrowserRouter,HashRouter, Route, Routes } from 'react-router-dom'
import Header from './Components/pages/navbar/Header';
import Footer from './Components/pages/navbar/Footer';
import Home from './Components/pages/Home';
import Alfred from './Components/pages/about/Alfred';
import Neville from './Components/pages/about/Neville';
import Contact from './Components/pages/Contact';
import Team from './Components/pages/about/Team';
import Donate from './Components/pages/Donate';
import News from './Components/pages/News';
import Patients from './Components/pages/Patients';
import FloatingButton from './Components/pages/FloatingButton';


function App() {
  return (
    <div>
      <HashRouter>
      <Header/>
      <Routes>
          <Route path='/' element={<Home/>}></Route>
          <Route path='/alfred' element={<Alfred/>}></Route>
          <Route path='/neville' element={<Neville/>}></Route>
          <Route path='/contact' element={<Contact/>}></Route>
          <Route path='/team' element={<Team/>}></Route>
          <Route path='/donate' element={<Donate/>}></Route>
          <Route path='/news' element={<News/>}></Route>
          <Route path='/patients' element={<Patients/>}></Route>
          <Route path='/button' element={<FloatingButton/>}></Route>
      </Routes>

      <Footer/>
      </HashRouter>

    </div>
  );
}

export default App;
