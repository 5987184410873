import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { submitContactForm } from '../pages/ApiService';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile:'',
    subject:'',
    message: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
      await submitContactForm(formData);
      setSuccessMessage('Form submitted successfully!');
      setFormData({ name: '', email: '', mobile: '',subject:'',message:''});
    } catch (error) {
      setErrorMessage('Failed to submit form. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      {/* ----------------------------breadcrumbs------------------------ */}
      <div className="bradcam_area breadcam_bg overlay d-flex align-items-center justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="bradcam_text text-center">
                <h3>Contact</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="contact-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="contact-title">Get in Touch</h2>
            </div>
            <div className="col-lg-8">
              <form className="form-contact contact_form" onSubmit={handleSubmit} noValidate>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control valid"
                        name="name"
                        id="name"
                        type="text"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Enter your name"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control valid"
                        name="email"
                        id="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Email"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        className="form-control valid"
                        name="mobile"
                        id="mobile"
                        type="number"
                        value={formData.mobile}
                        onChange={handleChange}
                        placeholder="mobile"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        className="form-control valid"
                        name="subject"
                        id="subject"
                        type="text"
                        value={formData.subject}
                        onChange={handleChange}
                        placeholder="subject"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        className="form-control w-100"
                        name="message"
                        id="message"
                        cols="30"
                        rows="9"
                        value={formData.message}
                        onChange={handleChange}
                        placeholder="Message"
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
                <div className="form-group mt-3">
                  <button type="submit" className="button button-contactForm boxed-btn" disabled={isSubmitting}>
                    {isSubmitting ? 'Submitting...' : 'Send'}
                  </button>
                </div>
              </form>
            </div>
            <div className="col-lg-3 offset-lg-1">
              <div className="media contact-info">
                <span className="contact-info__icon"><i className="ti-home"></i></span>
                <div className="media-body">
                  <h3>ALFRED SOLOMON HEART FOUNDATION:</h3>
                  <p>No 15, Apollo children’s Hospital,
                    IP- Block, 4th floor,
                    Safee Mohammed Road,
                    Greams Road, Thousands Lights,
                    Chennai – 600006.</p>
                </div>
              </div>
              <div className="media contact-info">
                <span className="contact-info__icon"><i className="ti-tablet"></i></span>
                <div className="media-body">
                  <h3 style={{ fontFamily: 'monospace', fontSize: '19px' }}>
                    <Link to="tel:+919655027828">+91 9655027828</Link>
                  </h3>
                  <p>Mon to Fri 9am to 6pm</p>
                </div>
              </div>
              <div className="media contact-info">
                <span className="contact-info__icon"><i className="ti-email"></i></span>
                <div className="media-body">
                  <h3><Link to="mailto:nevillesolomon@gmail.com">nevillesolomon@gmail.com</Link></h3>
                  <p>Send us your query anytime!</p>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-5 pb-4">
            <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15545.429769666142!2d80.2460065!3d13.0765183!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266772743ffff%3A0xf700d21d4673d181!2sAlfred%20Solomon%20Heart%20Foundation!5e0!3m2!1sen!2sin!4v1720502325690!5m2!1sen!2sin"
              width="100%"
              height="480px"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
