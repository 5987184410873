// import React from 'react'
// import { Link } from 'react-router-dom';
// import FloatingButton from '../FloatingButton';


// const Alfred = () => {
//     return (
//         <div>
//             {/* ----------------------------breadcrumbs------------------------ */}
//             <div className="bradcam_area breadcam_bg overlay d-flex align-items-center justify-content-center">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-xl-12">
//                             <div className="bradcam_text text-center">
//                                 <h3>About US</h3>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <div className="latest_activites_area mt-5 mb-4">
//             <div className=" video_bg_1 video_activite   col-lg-6">
//                     <Link className="popup-video" to="https://youtube.com/shorts/eiiBTy7X_h0?si=ylsVXWFZGXbubrsr">
//                     </Link>
//                 </div>
//                 <div className="container">
//                     <div className="row justify-content-end">
//                         <div className="col-lg-7">
//                             <div className="activites_info">
//                                 <div className="section_title">
//                                     <h3> <span>Tribute To Mr. Alfred Solomon </span></h3>
//                                 </div>
//                                 <p className="para_1">Mr Alfred Solomon was born on August 4, 1940 at Nagercoil . He was the second of five children born to Mr Solomon Abel and Mrs Elsamma Grace. He had an excellent academic record in school and went on to Loyola College where he completed  B.Sc  (Mathematics) and BA (Literature) .  He was an accomplished basketball player for Madras University.  While studying MA at Madras Christian College, he was selected as a probationary officer in Bank of Baroda. He subsequently joined State Bank of India in 1965 and guided by the hand of God, had a meteoric rise.
//                                     He was Chairman of Catholic Syrian Bank from 1992 to 1996.  At the time of his untimely demise,  he was Chief General Manager of State Bank of India, Andhra Pradesh. </p>
//                                 <p className="para_1">Mr Alfred Solomon married Mrs Lalitha Solomon in 1966 and they had only one child, Dr Neville Solomon. Alfred Solomon imbued his son with discipline, honesty and integrity. He motivated Neville to first take up medicine, subsequently surgery and finally to become a cardiac surgeon.

//                                 </p>
//                                 <p className="para_1">Mr Alfred Solomon passed away in 2000 following coronary artery bypass grafting surgery.  He is survived by his wife, Lalitha, son, daughter-in-law Sheeba and granddaughters Tanya and Nadia.

//                                     The Alfred Solomon Heart Foundation is an endeavour on the part of the Solomon family to achieve the ideals set forth by Alfred Solomon to help the poor and to utilize the skills he had facilitated his son to acquire to reach out to the needy who require treatment for heart disease. An endowment fund has been set up in his memory at Loyola College, Chennai to award to toppers in the department of Mathematics.</p>
//                                 <Link to="#" data-scroll-nav="1" className="boxed-btn4">Donate Now</Link>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <FloatingButton/>
//         </div>
//     )
// }

// export default Alfred

import React from 'react'
import { Link } from 'react-router-dom';
import FloatingButton from '../FloatingButton';


const Alfred = () => {
    return (
        <div>
            {/* ----------------------------breadcrumbs------------------------ */}
            <div className="bradcam_area breadcam_bg overlay d-flex align-items-center justify-content-center">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="bradcam_text text-center">
                                <h3>About US</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           

            <div className="container mt-5 mb-5">
            <h3 > <span>Tribute To Mr. Alfred Solomon </span></h3>

                <div>
                <p className="about_p">Mr Alfred Solomon was born on August 4, 1940 at Nagercoil . He was the second of five children born to Mr Solomon Abel and Mrs Elsamma Grace. He had an excellent academic record in school and went on to Loyola College where he completed  B.Sc  (Mathematics) and BA (Literature) .  He was an accomplished basketball player for Madras University.  While studying MA at Madras Christian College, he was selected as a probationary officer in Bank of Baroda. He subsequently joined State Bank of India in 1965 and guided by the hand of God, had a meteoric rise.
                        He was Chairman of Catholic Syrian Bank from 1992 to 1996.  At the time of his untimely demise,  he was Chief General Manager of State Bank of India, Andhra Pradesh. </p>
                    <p className="about_p">Mr Alfred Solomon married Mrs Lalitha Solomon in 1966 and they had only one child, Dr Neville Solomon. Alfred Solomon imbued his son with discipline, honesty and integrity. He motivated Neville to first take up medicine, subsequently surgery and finally to become a cardiac surgeon.

                    </p>
                    <p className="about_p">Mr Alfred Solomon passed away in 2000 following coronary artery bypass grafting surgery.  He is survived by his wife, Lalitha, son, daughter-in-law Sheeba and granddaughters Tanya and Nadia.

                        The Alfred Solomon Heart Foundation is an endeavour on the part of the Solomon family to achieve the ideals set forth by Alfred Solomon to help the poor and to utilize the skills he had facilitated his son to acquire to reach out to the needy who require treatment for heart disease. An endowment fund has been set up in his memory at Loyola College, Chennai to award to toppers in the department of Mathematics.</p>
                </div>

            </div>
            
            

            
            <FloatingButton />
        </div>
    )
}

export default Alfred