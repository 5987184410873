import axios from "axios";

const BASE_URL = "https://ashf.co.in/api/api";

const ApiService = axios.create({
  baseURL: BASE_URL,
});



export const submitContactForm = async (formData) => {
  try {
    const response = await ApiService.post("/contact/", formData);
    return response.data;
  } catch (error) {
    console.error("Error submitting contact form:", error);
    throw error;
  }
};

export const submitQueryForm = async (formData) => {
    try {
      const response = await ApiService.post("/queries/", formData);
      return response.data;
    } catch (error) {
      console.error("Error submitting Query form:", error);
      throw error;
    }
  };
