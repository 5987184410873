import React from 'react'
import FloatingButton from '../FloatingButton'


const Team = () => {
    return (
        <div>
            {/* ----------------------------breadcrumbs------------------------ */}
            <div className="bradcam_area breadcam_bg overlay d-flex align-items-center justify-content-center">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="bradcam_text text-center">
                                <h3>About US</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our_volunteer_area team_section_padding">
                <div className="container ">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="section_title text-center mb-5">
                                <h3><span>Our Team</span></h3>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row justify-content-center">
                        
                        <div className="col-lg-6 col-md-6">
                            <div className="single_volenteer">
                                <div className="volenteer_thumb">
                                    <img src="assets/img/blog/team1.jpg" alt="" />
                                </div>
                                <div className="voolenteer_info d-flex align-items-end">
                                   
                                    <div className="info_inner">
                                        <h4>Pediatric Cardiac Surgeon</h4>
                                        <p>Dr Swaminathan V</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div> */}
                </div>
            </div>

            <div className="team-container">

                <div className="cards">

                    
                <div className="card text-align-center">
                        <div className="card__img">
                            <img src="assets/img/post/team1.jpg" alt="" />
                        </div>
                        <div className="cart-content">
                            <h3>Pediatric Cardiac Surgeon:</h3>
                            <p>Dr Neville Solomon, Dr Swaminathan V</p>


                        </div>
                    </div>

                    

                    <div className="card text-align-center">
                        <div className="card__img">
                            <img src="assets/img/post/team2.jpg" alt="" />
                        </div>
                        <div className="cart-content">
                            <h3>Pediatric Cardiologists:</h3>
                            <p>Dr CS Muthukumaran, Dr Anuradha Sridhar, Dr Prasad Manne, Dr Sangeetha V, Dr Rajaguru.</p><br></br>
                           

                        </div>
                    </div>

                    <div className="card ">
                        <div className="card__img">
                            <img src="assets/img/post/team2.jpg" alt="" />
                        </div>
                        <div className="cart-content">
                        <h3>Pediatric Cardiac Anaesthetist:</h3>
                        <p>Dr Rajagopal Jeganathan, Dr Hemalatha Sridhar.</p>


                        </div>
                    </div>

                    <div className="card ">
                        <div className="card__img">
                            <img src="assets/img/post/team2.jpg" alt="" />
                        </div>
                        <div className="cart-content">
                            <h3>Pediatric Cardiac Intensivists: </h3>
                            <p>Dr Suchitra Ranjit, Dr Indira Jayakumar, Dr Rajeswari Arali, Dr Priyavarthini V, Dr Vasanth kumar S, Dr Chidambaram.

</p>


                        </div>
                    </div>

                    <div className="card text-align-center">
                        <div className="card__img">
                            <img src="assets/img/post/Team3.jpg" alt="" />
                        </div>
                        <div className="cart-content">
                            <h3>Pediatric Perfusionists : </h3>
                            <p> Mr Sundara Rajan, Mr Sriram, Mr Saravanan.</p><br></br>
                            <h3>Physician Assistant : </h3>
                            <p>Ms Esther mary, Mr Janath Kumar.</p>


                        </div>
                    </div>

                    <div className="card text-align-center">
                        <div className="card__img">
                            <img src="assets/img/post/team4.jpg" alt="" />
                        </div>
                        <div className="cart-content">
                            <h3>Pediatric Cardiology/Cardiac Surgery coordinators:</h3>
                            <p>Mr Deepak, Mrs Narmada.</p><br></br>
                            <h3>Echo technicians:</h3>
                            <p> Mr Nagaraj, Mr Karthi, Mrs Hepsiba.</p>


                        </div>
                    </div>

                </div>


            </div>
            <FloatingButton />
        </div>
    )
}

export default Team