import React from 'react';
import { Link } from 'react-router-dom';


const FloatingButton = () => {
  return (
    <div>
         <Link to="tel:+91 9655027828">
                <button class="btn-floating phone">
                    <img src="https://i.imgur.com/FZuns9L.png" alt="Phone" />
                    <span>Phone</span>
                </button>
            </Link>

            <Link to="https://www.whatsapp.com/" target="_blank">
                <button class="btn-floating whatsapp">
                    <img src="https://i.imgur.com/LBW2Lso.png" alt="WhatsApp" />
                    <span>Whatsapp</span>
                </button>
            </Link>

            <Link to="mailto:nevillesolomon@gmail.com" target="_blank">
    <button class="btn-floating email">
        {/* <img src="https://www.google.com/url?sa=i&url=https%3A%2F%2Fpixabay.com%2Fimages%2Fsearch%2Femail%2F&psig=AOvVaw1VLekef28SuMShV_LczKUm&ust=1720695676658000&source=images&cd=vfe&opi=89978449&ved=0CBEQjRxqFwoTCPjZj4epnIcDFQAAAAAdAAAAABAE" alt="Email" /> */}
        <i className="fa fa-envelope"></i>
        <span>Email</span>
    </button>
</Link>
    </div>
  )
}

export default FloatingButton