import React from 'react'
import FloatingButton from './FloatingButton'

const Patients = () => {
    return (
        <div>
              {/* ----------------------------breadcrumbs------------------------ */}
      <div className="bradcam_area breadcam_bg overlay d-flex align-items-center justify-content-center">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="bradcam_text text-center">
                                <h3>Patients</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="popular_causes_area section_padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="section_title text-center mb-5">
                                <h3><span>Popular Causes</span></h3>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <div className="causes_active ">
                                <div className="single_cause">
                                    <div className="thumb">
                                        <img src="assets/img/causes/patient1.jpg" alt="" />
                                    </div>
                                    <div className="causes_content">
                                     
                                        
                                        <h4>Help us </h4>
                                        <p>Baby babu 8 month old male child came to us with complaints of frequent respiratory tract infections and failure to thrive. He was diagnosed to have large parimembranous Ventricular septal defect with small patent ductus arteriosus with severe pulmonary artery hypertension. He underwent successful ventricular septal defect closure with pda ligation and was discharged home 1 week after the surgery.</p>
                                    </div>
                                </div>
                            </div>
                            </div>


                            <div className="col-lg-6">

                                <div className="single_cause">
                                    <div className="thumb">
                                        <img src="assets/img/causes/patient2.jpg" alt="" />
                                    </div>
                                    <div className="causes_content">
                                      
                                      
                                        <h4>Help us</h4>
                                        <p>Baby Thupakula Moshaiah 8 years old male child presented with complaints of breathlessness on exertion, history of cyanosis with squatting episodes. Child was detected to have heart disease at 4 years of age but was not under follow up or treatment. He was detected to have tricuspid atresia with normally related great arteries, large atrial septal defect amounting to single atrium, restrictive bulbo ventricle foramen with severe infundibular and valvar pulmonary stenosis and confluent branch pulmonary arteries. He underwent bidirectional Glenn shunt and was discharged with stable hemodynamics.</p>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div> */}
                <section class="patient-card-container">
                <div class="card-main">
                    <div class="card-img">
                        <img src="assets/img/causes/patient1.jpg" alt="img"/>
                    </div>
                    <div class="card-content">
                        
                        <p class="patient-card-description">
                           Baby babu 8 month old male child came to us with complaints of frequent respiratory tract infections and failure to thrive. He was diagnosed to have large parimembranous Ventricular septal defect with small patent ductus arteriosus with severe pulmonary artery hypertension. He underwent successful ventricular septal defect closure with pda ligation and was discharged home 1 week after the surgery.
                        </p>
                        
                    </div>
                </div>
                <div class="card-main">
                    <div class="card-img">
                        <img src="assets/img/causes/patient2.jpg" alt="img"/>
                    </div>
                    <div class="card-content">
                        
                        <p class="patient-card-description">
                        Baby Thupakula Moshaiah 8 years old male child presented with complaints of breathlessness on exertion, history of cyanosis with squatting episodes. Child was detected to have heart disease at 4 years of age but was not under follow up or treatment. He was detected to have tricuspid atresia with normally related great arteries, large atrial septal defect amounting to single atrium, restrictive bulbo ventricle foramen with severe infundibular and valvar pulmonary stenosis and confluent branch pulmonary arteries. He underwent bidirectional Glenn shunt and was discharged with stable hemodynamics.


                        </p>
                        
                    </div>
                </div>
                <div class="card-main">
                    <div class="card-img">
                        <img src="assets/img/causes/patient3.jpg" alt="img"/>
                    </div>
                    <div class="card-content">
                        
                        <p class="patient-card-description">
                        Baby. Pranvitha Reddy.M , 2 years old female, detected to have congenital heart disease at 1 month of age. On routine follow up, she was reffered to s for further work up. She was diagnosied to have Ostium secundum ASD with left upper pulmonary vein draining into innominate vein. She under went partial anomalous pulmonary venous drainage repair( Rerouting of left pulmonary vein into LA Appentage) and ostium secundum ASD closure using autologus pericardium. She was discharged on 4th post operative day with stable hemodynamics. Child was doing well on follow up.




                        </p>
                        
                    </div>
                </div>
                <div class="card-main">
                    <div class="card-img">
                        <img src="assets/img/causes/patient4.jpg" alt="img"/>
                    </div>
                    <div class="card-content">
                        
                        <p class="patient-card-description">
                        9 month old venkatesh was literally gasping for breatth when referred to us. The baby weighed only 5 kgs and had been diagnosed outside as a case of atrial septal defect with severe pulmonary hypertension. When our cardiologist did the echo, we diagnosed a membrane sitting astride the mitral valve almost totally obstructing the mitral valve. The baby needed urgent surgery. The Alfred Solomon Heart Foundation stepped in and sponsored the surgery. The surgery was uneventful and the baby was soon off the ventilator and cured for life.




                        </p>
                        
                    </div>
                </div>
                <div class="card-main">
                    <div class="card-img">
                        <img src="assets/img/causes/patient5.jpg" alt="img"/>
                    </div>
                    <div class="card-content">
                        
                        <p class="patient-card-description">
                        Baby. Sana, 10years old female child diagnosed to have congenital heart disease- Tetralogy of fallot with absent pulmonary valve syndrome. Child was sick and required open heart surgery. Parents could not afford for surgery.

Alfred Solomon Heart Foundation came forward to help the child for surgery. We took their financial burden as ours and helped them. Patient underwent Tetralogy of Fallot- Intracardiac repair+ Transannular patch + LPA plasty. She was discharged in stable condition. On followup child was doing good.




                        </p>
                        
                    </div>
                </div>
                <div class="card-main">
                    <div class="card-img">
                        <img src="assets/img/causes/patient6.jpeg" alt="img"/>
                    </div>
                    <div class="card-content">
                        
                        <p class="patient-card-description">
                        Baby Guduru nagamma 8 month old female child was brought with complaints of delayed mile stones, suck-rest-suck cycle, cyanosis aggravated on crying and excessive sweating while feeding. She was diagnosed to have dextrocardia, large inlet ventricular septal defect amounting to single ventricle, pulmonary atresia with confluent good sized branch pulmonary arteries. After multidisciplinary team meeting she was taken up for surgery. She underwent bidirectional Glenn shunt with PDA clipping. She recovered well and was discharged with stable hemodynamics.




                        </p>
                        
                    </div>
                </div>
                <div class="card-main">
                    <div class="card-img">
                        <img src="assets/img/causes/patient7.jpg" alt="img"/>
                    </div>
                    <div class="card-content">
                        
                        <p class="patient-card-description">
                        Baby chandu 10 month old male child diagnosed to have large subarterial Ventricular septal defect with severe pulmonary artery hypertension. He underwent successful ventricular septal defect closure and was discharged home 1 week after the surgery.




                        </p>
                        
                    </div>
                </div>
                <div class="card-main">
                    <div class="card-img">
                        <img src="assets/img/causes/patient8.jpg" alt="img"/>
                    </div>
                    <div class="card-content">
                        
                        <p class="patient-card-description">
                        Hareeshva 1 year old male child, detected to have congenital heart disease at birth on routine examination was referred to us for further work up. He was diagnosed to have sinus venosus ASD with right pulmonary vein draining into SVC to RA junction(PAPVC). He underwent Partial anomolous pulmonary venous drainage repair using 2 patch technique. He was discharged on 5 th post operative day with stable hemodynamics. Child was doing well on follow up.




                        </p>
                        
                    </div>
                </div>
                <div class="card-main">
                    <div class="card-img">
                        <img src="assets/img/causes/patient9.jpg" alt="img"/>
                    </div>
                    <div class="card-content">
                        
                        <p class="patient-card-description">
                        Master Srihari 17 years old male child presented to us with complaints of dyspnoea on exertion for past 1 year.He was detected to have congenital heart disease in his childhood period but was neglected. He was diagnosed by echocardiogram to have large subarterial VSD restricted by RCC prolapse with severe valvar pulmonary stenosis. He underwent cardiac catheterization as a part of presurgical work up.




                        </p>
                        
                    </div>
                </div>
                <div class="card-main">
                    <div class="card-img">
                        <img src="assets/img/causes/patient10.jpg" alt="img"/>
                    </div>
                    <div class="card-content">
                        
                        <p class="patient-card-description">
                        Ademma is a 13 year old extremely poor girl with a complex congenital cardiac condition called Tetralogy of Fallot. The child was blue and on the verge of collapsing. The child was evaluated by Dr Anuradha Sridhar, our Pediatric Cardiologist, in her Nellore Clinic and she opined that the child needed urgent surgery.




                        </p>
                        
                    </div>
                </div>
                <div class="card-main">
                    <div class="card-img">
                        <img src="assets/img/causes/patient11.jpg" alt="img"/>
                    </div>
                    <div class="card-content">
                        
                        <p class="patient-card-description">
                        Baby. Sharon Kovur, 8 months old female, detected to congenital heart disease at 10days, on routine follow up. She was refered to us for further work up. She was diagnosed to have large perimembraneous VSD. She underwent Large perimembraneous VSD closure using PTFE patch. She was discharged on 5th post operative day with stable haemodynamics. Child was doing well on follow up.




                        </p>
                        
                    </div>
                </div>
                <div class="card-main">
                    <div class="card-img">
                        <img src="assets/img/causes/patient12.jpg" alt="img"/>
                    </div>
                    <div class="card-content">
                        
                        <p class="patient-card-description">
                        Miss ankamma 10 yrs old female child was diagnosed to have large ventricular septal defect and was referred to us for further management. She underwent successful ventricular septal defect closure and was discharged home with stable hemodynamics.






                        </p>
                        
                    </div>
                </div>
                <div class="card-main">
                    <div class="card-img">
                        <img src="assets/img/causes/patient13.jpg" alt="img"/>
                    </div>
                    <div class="card-content">
                        
                        <p class="patient-card-description">
                        Baby. Jaikrishna, 2years old male diagnosed to have, one of the rare anomaly in congenital heart disease – Anomalous origin of left coronary artery from pulmonary artery (ALCAPA) and Severe mitral regurgitation. Parents were worried about the surgery and its cost. Alfred Solomon Heart Foundation came forward and changed their tears into happiness by helping them. Child underwent Anomalous origin of left coronary artery from pulmonary artery repair (ALCAPA repair) + Mitral valve repair. Child was discharged with stable haemodynamics.








                        </p>
                        
                    </div>
                </div>
                <div class="card-main">
                    <div class="card-img">
                        <img src="assets/img/causes/patient14.jpg" alt="img"/>
                    </div>
                    <div class="card-content">
                        
                        <p class="patient-card-description">
                        Baby. Hemasri, 3months old child weighing 3.4kg diagnosed to have Large Ventricular septal defect, Large atrial septal defect and moderate patent ductus arteriosus. Child was in congestive cardiac failure and was sick preoperatively. Parents were very poor, Alfred Solomon Heart foundation came forward and helped them for surgery. Child underwent Ventricular septal defect closure+ Atrial septal defect closure+ PDA ligation successfully. Child was discharged with stable condition.










                        </p>
                        
                    </div>
                </div>
                <div class="card-main">
                    <div class="card-img">
                        <img src="assets/img/causes/patient15.jpg" alt="img"/>
                    </div>
                    <div class="card-content">
                        <p class="patient-card-description">
                        Baby. Sivasree, 6months old female child diagnosed as congenital heart defect- Large
                        Perimembraneous Ventricular septal defect. Preoperatively child had breathing difficulty 
                        and failure to thrive. Alfred Solomon Heart foundation took off burden for the parents 
                        and sponsored for surgery. She underwent Ventricular septal defect closure. 
                        child is haemodynamically stable and discharged. Child is doing good on followup.
                        </p>             
                    </div>
                </div>
                <div class="card-main">
                    <div class="card-img">
                        <img src="assets/img/causes/patient16.jpg" alt="img"/>
                    </div>
                    <div class="card-content">
                        <p class="patient-card-description">
                        Gurudev, 6months old male child diagnosed to have congenital heart defect- Unroofed coronary sinus with large Ostium secundum Atrial septal defect. Child was in congestive cardiac failure, malnourished and struggled for life, so needed urgent surgery.
Since parents could not afford for surgery, Alfred Solomon Heart Foundation donated for surgery. With the team effort child underwent open heart surgery-ASD closure with unroofed coronary sinus repair successfully and was discharged in stable condition.
                        </p>             
                    </div>
                </div>
                <div class="card-main">
                    <div class="card-img">
                        <img src="assets/img/causes/patient17.jpg" alt="img"/>
                    </div>
                    <div class="card-content">
                        <p class="patient-card-description">
                        Baby. Serwin Ignatius, 3 years old male child had history of breathing difficulty, sweating while feeding. Echo cardiogram showed congenital heart disease- Tetralogy of fallot with severe infundibular and valvar pulmonary stenosis. Child required open heart surgery.

Alfred solomon Heart Foundation sponsored for surgery. Child underwent oen heart surgery, Tetralogy of fallot- Intracardiac repair+ Subannular patch+ open pulmonary valvotomy and was discharged with stable hemodynamics
                        </p>             
                    </div>
                </div>
                <div class="card-main">
                    <div class="card-img">
                        <img src="assets/img/causes/patient18.jpg" alt="img"/>
                    </div>
                    <div class="card-content">
                        <p class="patient-card-description">
                        Sanantha, 5 years old female child weighing 13kgs diagnosed to congenital heart disease- Large ostium secundum Atrial septal defect. Daughter of an electrician who struggling for daily needs. Parents were struggling to arrange money for surgery. Alfred Solomon Heart Foundation came forward to help financially. She underwent Atrial septal defect closure uneventfully and hemodynamically stable in Postoperative period.


                        </p>             
                    </div>
                </div>                
            </section>
            
                <FloatingButton/>
            </div>
    )
}

export default Patients