// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';

// const Header = () => {
//     const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
//     const [isAboutSubmenuOpen, setIsAboutSubmenuOpen] = useState(false);
//     const [isHoveringAbout, setIsHoveringAbout] = useState(false);


//     const handleAboutClick = (e) => {
//         e.preventDefault(); // Prevent default action for anchor tag
//         setIsAboutSubmenuOpen(!isAboutSubmenuOpen);
//     };

//     const handleMobileMenuToggle = () => {
//         setIsMobileMenuOpen(!isMobileMenuOpen);
//     };

   

//     const handleAboutMouseEnter = () => {
//         if (!isMobileMenuOpen) {
//             setIsAboutSubmenuOpen(true);
//             setIsHoveringAbout(true);
//         }
//     };

//     const handleAboutMouseLeave = () => {
//         setIsHoveringAbout(false);
//         setTimeout(() => {
//             if (!isHoveringAbout) {
//                 setIsAboutSubmenuOpen(false);
//             }
//         }, 300);
//     };

//     // Close About submenu when clicking outside the submenu area
//     const handleOutsideClick = (e) => {
//         if (!e.target.closest('.slicknav_nav')) {
//             setIsAboutSubmenuOpen(false);
//         }
//     };

//     // Attach event listener to handle clicks outside the submenu
//     React.useEffect(() => {
//         document.addEventListener('click', handleOutsideClick);
//         return () => {
//             document.removeEventListener('click', handleOutsideClick);
//         };
//     }, []);

//     return (
//         <div>
//             <header>
//                 <div className="header-area">
//                     <div className="header-top_area">
//                         <div className="container-fluid">
//                             <div className="row">
//                                 <div className="col-xl-6 col-md-12 col-lg-8">
//                                     <div className="short_contact_list">
//                                         <ul>
//                                             <li><Link to="tel:+919655027828"><i className="fa fa-phone"></i>+91 9655027828</Link></li>
//                                             <li><Link to="mailto:nevillesolomon@gmail.com"><i className="fa fa-envelope"></i>nevillesolomon@gmail.com</Link></li>
//                                         </ul>
//                                     </div>
//                                 </div>
//                                 <div className="col-xl-6 col-md-6 col-lg-4">
//                                     <div className="social_media_links d-none d-lg-block">
//                                         <Link to="#"><i className="fa fa-facebook"></i></Link>
//                                         <Link to="#"><i className="fa fa-pinterest-p"></i></Link>
//                                         <Link to="#"><i className="fa fa-linkedin"></i></Link>
//                                         <Link to="#"><i className="fa fa-twitter"></i></Link>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div id="sticky-header" className="main-header-area">
//                         <div className="container-fluid">
//                             <div className="row align-items-center">
//                                 <div className="col-xl-3 col-lg-3">
//                                     <div className="logo" style={{ backgroundColor: 'white' }}>
//                                         <Link to="/">
//                                             <img className='header-logo-img' src="assets/img/logo-1.png" alt="logo" />
//                                         </Link>
//                                     </div>
//                                 </div>
//                                 <div className="col-xl-9 col-lg-9">
//                                     <div className="main-menu d-none d-lg-block">
//                                         <nav>
//                                             <ul id="navigation" style={{ lineHeight: 0.1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                                                 <li><Link to="/">home</Link></li>
//                                                 <li className='pt-1'
//                                                     onMouseEnter={handleAboutMouseEnter}
//                                                     onMouseLeave={handleAboutMouseLeave}>
//                                                     <Link to="#" onClick={handleAboutClick}>
//                                                         About <i className="ti-angle-down"></i>
//                                                     </Link>
//                                                     <ul className={`submenu ${!isMobileMenuOpen && isHoveringAbout ? 'open' : ''}`}>
//                                                         <li><Link to="/alfred">Alfred Solomon</Link></li>
//                                                         <li><Link to="/neville">Neville Solomon</Link></li>
//                                                         <li><Link to="/team">Our Team</Link></li>
//                                                     </ul>
//                                                 </li>
//                                                 <li><Link to="/patients">Patients</Link></li>
//                                                 <li><Link to="/news">News</Link></li>
//                                                 <li><Link to="/contact">Contact</Link></li>
//                                             </ul>
//                                         </nav>
//                                         <div className="Appointment" pb-1 style={{ lineHeight: 0.1 }}>
//                                             <div className="book_btn  d-none d-lg-block" >
//                                                 <Link data-scroll-nav='1' to="/donate" >Make a Donate</Link>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-12">
//                                     <div className="mobile_menu d-block d-lg-none">
//                                         <div className="slicknav_menu">
//                                             <Link to="#" onClick={handleMobileMenuToggle} className="slicknav_btn slicknav_open">
//                                                 <span className="slicknav_menutxt">MENU</span>
//                                                 <span className="slicknav_icon">
//                                                     <span className="slicknav_icon-bar"></span>
//                                                     <span className="slicknav_icon-bar"></span>
//                                                     <span className="slicknav_icon-bar"></span>
//                                                 </span>
//                                             </Link>
//                                             <ul className={`slicknav_nav ${isMobileMenuOpen ? 'slicknav_open' : 'slicknav_hidden'}`}>
//                                                 <li><Link to="/">home</Link></li>
//                                                 <li className="slicknav_collapsed slicknav_parent">
//                         <a href="#" className="slicknav_item slicknav_row" onClick={handleAboutClick}>
//                             About <span className="slicknav_arrow">{isAboutSubmenuOpen ? '-' : '+'}</span>
//                         </a>
//                         <ul className={`submenu ${isAboutSubmenuOpen ? 'slicknav_open' : 'slicknav_hidden'}`}>
//                             <li><Link to="/alfred">Alfred Solomon</Link></li>
//                             <li><Link to="/neville">Neville Solomon</Link></li>
//                             <li><Link to="/team">Our Team</Link></li>
//                         </ul>
//                     </li>
//                                                 <li><Link to="/patients">Patients</Link></li>
//                                                 <li><Link to="/news">News</Link></li>

//                                                 <li><Link to="/contact">Contact</Link></li>
//                                             </ul>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </header>
//         </div>
//     );
// };

// export default Header;


import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { GiHamburgerMenu } from "react-icons/gi";

const Header = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isAboutSubmenuOpen, setIsAboutSubmenuOpen] = useState(false);
    const [isHoveringAbout, setIsHoveringAbout] = useState(false);

    const handleAboutClick = (e) => {
        e.preventDefault();
        setIsAboutSubmenuOpen(!isAboutSubmenuOpen);
    };

    const handleMobileMenuToggle = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleAboutMouseEnter = () => {
        if (!isMobileMenuOpen) {
            setIsAboutSubmenuOpen(true);
            setIsHoveringAbout(true);
        }
    };

    const handleAboutMouseLeave = () => {
        setIsHoveringAbout(false);
        setTimeout(() => {
            if (!isHoveringAbout) {
                setIsAboutSubmenuOpen(false);
            }
        }, 300);
    };

    const handleOutsideClick = (e) => {
        if (!e.target.closest('.submenu') && !e.target.closest('.slicknav_nav')) {
            setIsAboutSubmenuOpen(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <div>
            <header>
                <div className="header-area">
                    <div className="header-top_area">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-6 col-md-12 col-lg-8">
                                    <div className="short_contact_list">
                                        <ul>
                                            <li><Link to="tel:+919655027828"><i className="fa fa-phone"></i>+91 9655027828</Link></li>
                                            <li><Link to="mailto:nevillesolomon@gmail.com"><i className="fa fa-envelope"></i>nevillesolomon@gmail.com</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* <div className="col-xl-6 col-md-6 col-lg-4">
                                    <div className="social_media_links d-none d-lg-block">
                                        <Link to="#"><i className="fa fa-facebook"></i></Link>
                                        <Link to="#"><i className="fa fa-pinterest-p"></i></Link>
                                        <Link to="#"><i className="fa fa-linkedin"></i></Link>
                                        <Link to="#"><i className="fa fa-twitter"></i></Link>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div id="sticky-header" className="main-header-area">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-xl-3 col-lg-3">
                                    <div className="logo" style={{ backgroundColor: 'white' }}>
                                        <Link to="/">
                                            <img className='header-logo-img' src="assets/img/logo-1.png" alt="logo" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-xl-9 col-lg-9">
                                    <div className="main-menu d-none d-lg-block">
                                    <nav>
    <ul id="navigation" style={{ lineHeight: 0.1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <li><Link to="/">home</Link></li>
        <li className='pt-1'
            onMouseEnter={handleAboutMouseEnter}
            onMouseLeave={handleAboutMouseLeave}>
            <Link to="#" onClick={handleAboutClick}>
                About <i className="ti-angle-down"></i>
            </Link>
            <ul className={`submenu ${!isMobileMenuOpen && isAboutSubmenuOpen ? 'open' : ''}`}>
                <li><Link to="/alfred">Alfred Solomon</Link></li>
                <li><Link to="/neville">Neville Solomon</Link></li>
                <li><Link to="/team">Our Team</Link></li>
            </ul>
        </li>
        <li><Link to="/patients">List of Patients</Link></li>
        <li><Link to="/news">News</Link></li>
        <li><Link to="/contact">Contact</Link></li>
    </ul>
    <div className="Appointment" style={{ lineHeight: 0.1, display: 'flex', alignItems: 'flex-end' }}>
        <div className="book_btn d-none d-lg-block">
            <Link data-scroll-nav='1' to="/donate" className="donate-btn">Make a Donate</Link>
        </div>
    </div>
</nav>

                                        {/* <div className="Appointment" pb-1 style={{ lineHeight: 0.1,display: 'flex', alignItems:"flex-end"  }}>
                                            <div className="book_btn  d-none d-lg-block " >
                                                <Link data-scroll-nav='1' to="/donate"  >Make a Donate</Link>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mobile_menu d-block d-lg-none">
                                        <div className="slicknav_menu">
                                            <Link to="#" onClick={handleMobileMenuToggle} className="slicknav_btn slicknav_open">
                                                <span className="slicknav_menutxt">MENU</span>
                                                <span className="slicknav_icon">
                                                    {/* <span className="slicknav_icon-bar"></span>
                                                    <span className="slicknav_icon-bar"></span>
                                                    <span className="slicknav_icon-bar"></span> */}
                                                    <GiHamburgerMenu style={{marginTop:"9px",fontSize:"20px",color:"#E1322E"}}/>
                                                </span>
                                            
                                            </Link>
                                            <ul className={`slicknav_nav ${isMobileMenuOpen ? 'slicknav_open' : 'slicknav_hidden'}`}>
                                                <li><Link to="/">home</Link></li>
                                                <li className="slicknav_collapsed slicknav_parent">
                                                    <Link to="#" className="slicknav_item slicknav_row" onClick={handleAboutClick}>
                                                    About <span className="slicknav_arrow"></span>
                                                        {/* About <span className="slicknav_arrow">{isAboutSubmenuOpen ? '-' : '+'}</span> */}
                                                    </Link>
                                                    <ul className={`submenu ${isAboutSubmenuOpen ? 'slicknav_open' : 'slicknav_hidden'}`}>
                                                        <li><Link to="/alfred">Alfred Solomon</Link></li>
                                                        <li><Link to="/neville">Neville Solomon</Link></li>
                                                        <li><Link to="/team">Our Team</Link></li>
                                                    </ul>
                                                </li>
                                                <li><Link to="/patients">List of Patients</Link></li>
                                                <li><Link to="/news">News</Link></li>
                                                <li><Link to="/contact">Contact</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
};

export default Header;
