import React from 'react'
import { Link } from 'react-router-dom';
import FloatingButton from '../FloatingButton';


const Neville = () => {
    return (
        <div>
            {/* ----------------------------breadcrumbs------------------------ */}
            <div className="bradcam_area breadcam_bg overlay d-flex align-items-center justify-content-center">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="bradcam_text text-center">
                                <h3>About US</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h3 className='mt-4 mb-3  text-center'><span>Mr. Neville Solomon</span></h3>
            <div className='container'>

            <div className=' neville row'>

                <div className='about-img col-lg-6'>
                    <img src='assets/img/about/neville.jpg' alt='Neville Solomon' />
                </div>

                <div className='about-neville col-lg-6'>
                    <p>Dr Neville Solomon completed M.B.B.S. from the reputed Madras Medical College, Madras that is affiliated to the Madras University for undergraduate and postgraduate studies. After securing admission to postgraduate training program in General Surgery through All-India entrance examination, he pursued postgraduate studies at Medical College Hospital, Trivandrum, which is affiliated to Kerala University and successfully, completed MS (General Surgery) in October 1995. He was selected for advanced training in cardiovascular and thoracic surgery at Madras Medical College in April 1996 through a competitive examination held at state level. After two years of training, he passed the M.Ch. examination in March 1998.</p>
                </div>
            </div>
            </div>
            <div className='container mt-3 mb-3'>

                <p>Dr Solomon subsequently joined the Department of Cardiothoracic and Vascular Surgery, Apollo Hospital, Chennai under Dr. M.R.Girinath as Senior Registrar and was soon promoted to the position of Junior Consultant. This is one of the premier centers in the country performing 1500-2000 open-heart procedures annually.

                    <br></br>In 2001, he was appointed at Green Lane Hospital, Auckland, New Zealand as Registrar in Cardiothoracic Surgery. Having received considerable training in adult cardiac surgery in India, he concentrated on pediatric cardiac surgery and cardiac transplantation. He returned to India 10 years ago and was immediately hired as consultant cardiac surgeon to work with Dr M R Girinath.<br></br> Since February 2005, he has been an independent consultant heading his own unit in Apollo Hospital, Chennai and have been doing a wide range of cases ranging from off-pump CABG’s to congenital cardiac procedures.

                    Since July 2006, Dr Solomon has concentrated solely on pediatric and adult congenital cardiac work. He has performed over 3000 cardiac surgeries ranging from newborn babies with complex cardiac conditions to adults with congenital heart disease. He has been associated with over 6000 heart operations in his career.<br></br>

                    Dr Solomon along with his partner in pediatric cardiac surgery, Dr Swaminathan and pediatric cardiologists Dr CS Muthukumaran, Dr Anuradha Sreedhar and Dr Prasad Manne runs the Save a Child’s Heart initiative (SACHi) program at Apollo Children’s Hospital, Chennai. They have performed over many heart operations on poor children under the scheme. The operations are done either free or at subsidised rates. <br></br>Dr Solomon is the Chairman of Alfred Solomon Heart Foundation (www.ashf.in) which has partially funded over 80 heart operations. They have conducted free pediatric cardiac camps and clinics all over Tamil Nadu, Andhra Pradesh, Kerala, North-east India and Bangladesh and Africa (Nigeria, Tanzania, Zambia, Kenya, Sudan, etc)

                    <br></br>The unit is reknowned as a tertiary center with a team-oriented multidisciplinary approach to each child. All facilities are available such as ECMO, nitric oxide, renal replacement therapy and HFO ventilation. Children from all parts of the world such as Africa, Mideast, Sri Lanka and Bangladesh have their hearts mended at Apollo Children’s Hospital, Chennai! He recently led a team and performed the first open heart operations in the Kwara state of Nigeria.</p>

            </div>


            <FloatingButton />
        </div>
    )
}

export default Neville